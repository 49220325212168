import React from "react";
import { useDispatch } from 'react-redux'
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Link } from "react-router-dom";
import { logout } from '../actions'

export default () => {
  const dispatch = useDispatch();

  return (
    <div>
      <Link to="/calendar">
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Календарь" />
        </ListItem>
      </Link>
      <Link to="/" onClick={() => dispatch(logout())}>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Выход" />
        </ListItem>
      </Link>
    </div>
  );
}