export const FETCH_SET_STATE = 'FETCH_SET_STATE';
export const SUCCESS_SET_STATE = 'SUCCESS_SET_STATE';
export const FETCH_DATES = 'FETCH_DATES';
export const SUCCESS_DATES = 'SUCCESS_DATES';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
