import { 
    SHOW_ERROR,
    HIDE_ERROR,
} from './types';

export function showError(error) {
    return dispatch => {
        dispatch({
            type: SHOW_ERROR,
            error
        })
    
        setTimeout(() => {
            dispatch(hideError())
        }, 3000)
    }
}
export function hideError() {
    return {
        type: HIDE_ERROR
    }
}