import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

export default function withEmptyLayout(WrappedComponent) {
  return (props) => (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <WrappedComponent {...props}/>
    </Container>
  );
}
