import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import WeekDay from "../components/WeekDay";

const useStyles = makeStyles((theme) => ({
  week: {
    width: '100%',
    gridTemplate: 'repeat(1, 1fr)/repeat(7, 1fr)',
    display: 'grid',
    textAlign: 'center'
  },
}));

const weekdays = {
  0: 'Пн',
  1: 'Вт',
  2: 'Ср',
  3: 'Чт',
  4: 'Пт',
  5: 'Сб',
  6: 'Вс',
}

function Week(props) {
  const classes = useStyles();
  return (
    <div className={classes.week}>
        {
            [...Array(7).keys()].map(key => (
                <WeekDay key={key} name={weekdays[key]} isWeekend={key>=5} />
            ))
        }
    </div>
  );
}

export default Week;
