import axios from 'axios';
import { 
    FETCH_SET_STATE,
    SUCCESS_SET_STATE,
    FETCH_DATES,
    SUCCESS_DATES,
} from './types';
import store from '../store';
import {showError} from './error';

export const setStateSuccess = (year, month, day, state) => {
    return {
        type: SUCCESS_SET_STATE,
        year, month, day,
        state
    }
};
export const setStateRequest = () => {
    return {
        type: FETCH_SET_STATE,
    }
};
export const setState = (year, month, day, state) => {
    return async dispatch => {
        try {
            dispatch(setStateRequest())
            const response = await axios
                .post(`${process.env.REACT_APP_COMMON_SERVER_ADDRESS}/sec/state`, {
                    year, month, day, state
                }, {
                    headers: {
                        'X-Auth-Token': store.getState().auth.user.token,
                        'Accept': 'application/json',
                    }
                })
            if (!response.data.success) {
                throw new Error(response.data.error)
            }
            dispatch(setStateSuccess(year, month, day, state))
        } catch (error) {
            dispatch(showError(error))
        }
    };
};

export const fetchDatesRequest = (year) => {
    return {
        type: FETCH_DATES,
        year
    }
};
export const fetchDatesSuccess = (year, dates) => {
    return {
        type: SUCCESS_DATES,
        year,
        dates
    }
};
export const fetchDates = (year) => {
    return async dispatch => {
        try {
            dispatch(fetchDatesRequest(year))
            const response = await axios
                .post(`${process.env.REACT_APP_COMMON_SERVER_ADDRESS}/sec/dates`, {
                    year
                }, {
                    headers: {
                        'X-Auth-Token': store.getState().auth.user.token,
                        'Accept': 'application/json',
                    }
                })
            if (!response.data.success) {
                throw new Error(response.data.error)
            }
            dispatch(fetchDatesSuccess(year, response.data.data.dates))
        } catch (error) {
            dispatch(showError(error))
        }
    };
};