import { 
  LOGIN,
  LOGOUT
} from '../actions/types';

const localStoreState = localStorage['redux-store']
  ? JSON.parse(localStorage['redux-store']).auth
  : { auth: { user: null } };

const initialState = localStoreState

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, user: action.user }
    case LOGOUT:
      return { ...state, user: null }
    default:
      return state;
  }
}