import React from "react";
import { Route } from "react-router-dom";
import SignIn from "./pages/SignIn";
import Calendar from "./pages/Calendar";
import { PrivateRoute } from "./components/PrivateRoute";

const App = () => (
  <div>
    <Route exact path="/" component={SignIn} />
    <PrivateRoute exact path="/calendar" component={Calendar} />
  </div>
);
export default App;
