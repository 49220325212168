import axios from 'axios';
import { 
    LOGIN,
    LOGOUT,
} from './types';
import {showError} from './error';
import {showLoader, hideLoader} from './loader';

export const successLogin = (user) => {
    return {
        type: LOGIN,
        user
    }
};
export const logout = () => {
    return {
        type: LOGOUT
    }
};
export const login = (username, password, redirect) => {
    return async dispatch => {
      try {
        dispatch(showLoader())
        const response = await axios
            .post(`${process.env.REACT_APP_COMMON_SERVER_ADDRESS}/nosec/auth`, {
                username,
                password,
            })
        if (!response.data.success) {
            throw new Error(response.data.error)
        }
        dispatch(successLogin(response.data.data))
        dispatch(hideLoader())
        if (redirect) redirect()
      } catch (error) {
        dispatch(showError(error))
        dispatch(hideLoader())
      }
    }
};
