import React from "react";
import { useDispatch } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import Day from "../components/Day";
import { setState } from '../actions'

const useStyles = makeStyles((theme) => ({
  days: {
    width: '100%',
    gridTemplate: 'repeat(5, 1fr)/repeat(7, 1fr)',
    display: 'grid',
    textAlign: 'center'
  },
}));

function Days(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isWeekend = (year, month, day) => (new Date(year, month - 1, day)).getDay() % 6 === 0
  const getNextState = state => {
    return {
      work: 'free',
      free: 'work',
    }[state]
  }
  const handleClick = (year, month, day) => () => {
    dispatch(setState(year, month, day, getNextState(props.dates[`${year}-${month}-${day}`] || 'work')));
  }


  const startAt = new Date(props.year, props.month - 1, 1)
  const firstDayAt = startAt.getDay() === 0 ? 7: startAt.getDay()

  const days = [...Array(42).keys()].map(key => {
    if (key + 1 < firstDayAt) {
      return {
        name: '',
        state: false,
        isWeekend: false,
        handleClick: () => {}
      }
    }
    if (key + 1 - firstDayAt + 1 > new Date(props.year, props.month, 0).getDate()) {
      return {
        name: '',
        state: false,
        isWeekend: false,
        handleClick: () => {}
      }
    }
    return {
      name: (key + 1 - firstDayAt + 1).toString(),
      state: props.dates[`${props.year}-${props.month}-${key + 1 - firstDayAt + 1}`],
      isWeekend: isWeekend(props.year, props.month, key + 1 - firstDayAt + 1),
      handleClick: handleClick(props.year, props.month, key + 1 - firstDayAt + 1)
    }
  })

  return (
    <div className={classes.days}>
        {
            [...Array(42).keys()].map(key => (
                <Day
                  key={key}
                  name={days[key].name}
                  isWeekend={days[key].isWeekend}
                  click={days[key].handleClick}
                  state={days[key].state}
                />
            ))
        }
    </div>
  );
}

export default Days;
