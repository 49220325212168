import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Month from "../components/Month";

const useStyles = makeStyles((theme) => ({
    year: {
      margin: '20px 40px',
      width: '100%',
      gridTemplate: 'repeat(3, 1fr)/repeat(4, 210px)',
      display: 'grid'
    },
}));
  
function Year(props) {
  const classes = useStyles();
  return (
    <div className={classes.year}>
        {
            [...Array(12).keys()].map(key => (
                <Month dates={props.dates} key={key} year={props.year} month={key+1} />
            ))
        }
    </div>
  );
}

export default Year;
