import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Name from "../components/Name";
import Week from "../components/Week";
import Days from "../components/Days";

const useStyles = makeStyles((theme) => ({
    month: {
      width: '100%',
      padding: '10px'
    },
}));

function Month(props) {
  const classes = useStyles();
  return (
    <div className={classes.month}>
        <Name month={props.month} />
        <Week />
        <Days dates={props.dates} year={props.year} month={props.month} />
    </div>
  );
}

export default Month;
