import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    day: {
        width: '100%',
        color: 'gray'
    },
    weekend: {
      color: 'white',
      background: '#007cff'
    },
}));

function WeekDay(props) {
  const classes = useStyles();
  return (
    <div className={`${classes.day} ${ props.isWeekend && classes.weekend }`}>
        {props.name}
    </div>
  );
}

export default WeekDay;
