import { 
  SUCCESS_SET_STATE,
  SUCCESS_DATES,
 } from '../actions/types';

const initialState = {
  wait: true,
  year: 2021,
  dates: {},
}

export default function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_SET_STATE:
      return {
        ...state,
        dates: {
          ...state.dates,
          [`${action.year}-${action.month}-${action.day}`]: action.state,
        }
      }
    case SUCCESS_DATES:
      return {
        ...state,
        wait: false,
        year: action.year,
        dates: action.dates.reduce((acc, item) => {
          acc[`${item.year}-${item.month}-${item.day}`] = item.state
          return acc
        }, {})
      }
    default:
      return state;
  }
}