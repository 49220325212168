import { combineReducers } from 'redux';
import auth from './authReducer';
import date from './dateReducer';
import loader from './loaderReducer';
import error from './errorReducer';

export default combineReducers({
    date,
    loader,
    error,
    auth,
});