import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router'

import withEmptyLayout from "../layouts/empty";
import { login } from '../actions'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user)
  const loader = useSelector(state => state.loader.loader);
  const error = useSelector(state => state.error.error);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const classes = useStyles();

  function handleChangePassword(e) {
    setPassword(e.target.value);
  }
  function handleChangeUsername(e) {
    setUsername(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!(username && password)) {
      return;
    }
    dispatch(login(username, password, () => {
      const { from } = props.location.state || {
        from: { pathname: "/calendar" },
      };
      props.history.push(from);
    }))
  }

  if (user) {
    return <Redirect to="/calendar"/>
  }

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Вход
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Логин"
          name="username"
          autoFocus
          value={username}
          onChange={handleChangeUsername}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Пароль"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={handleChangePassword}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loader}
          variant="contained"
        >
          Войти
        </Button>
        {loader && <LinearProgress size={24} />}
        {error && <Alert elevation={6} variant="filled" severity="error">{error}</Alert>}
      </form>
    </div>
  );
};

export default withEmptyLayout(withRouter(SignIn));
