import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  day: {
    width: '100%',
    cursor: 'pointer'
  },
  weekend: {
    color: 'white',
    background: '#007cff'
  },
  free: {
    color: 'white',
    background: 'red'
  },
  work: {
    color: 'black',
    background: 'white'
  },
}));

function Day(props) {
  const classes = useStyles();
  return (
    <div className={`${classes.day} ${ props.isWeekend && classes.weekend } ${ props.state && classes[props.state] }`} onClick={props.click}>
        {props.name}
    </div>
  );
}

export default Day;
