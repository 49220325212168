import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    name: {
      width: '100%',
      textAlign: 'center',
      display: 'inline-block',
      background: '#e1e1e1'
    },
}));

const months = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь',
}

function Name(props) {
  const classes = useStyles();
  return (
    <h4 className={classes.name}>
        {months[props.month]}
    </h4>
  );
}

export default Name;
