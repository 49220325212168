import { 
  SHOW_LOADER,
  HIDE_LOADER
} from '../actions/types';

const initialState = {
  loader: false
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loader: true }
    case HIDE_LOADER:
      return { ...state, loader: false }
    default:
      return state;
  }
}