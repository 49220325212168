import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";

import Year from "../components/Year";
import withMainLayout from "../layouts/main";
import { fetchDates } from '../actions'

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '100%',
  },
  paper: {
  },
  button: {
    width: '100px'
  },
}));

function Calendar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.loader.loader);
  const error = useSelector(state => state.error.error);
  const dates = useSelector(state => state.date.dates);
  const year = useSelector(state => state.date.year);
  const wait = useSelector(state => state.date.wait);
  
  useEffect(() => {
    dispatch(fetchDates(year));
  }, [year]);

  if (wait) {
    return null
  }

  const setYear = delta => () => {
    dispatch(fetchDates(year + delta));
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Календарь {year}
          </Typography>
          <Year year={year} dates={dates} />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={loader}
            variant="contained"
            onClick={setYear(-1)}
          >
            {year-1}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={loader}
            variant="contained"
            onClick={setYear(+1)}
          >
            {year+1}
          </Button>
          {loader && <LinearProgress size={24} />}
          {error && <Alert elevation={6} variant="filled" severity="error">{error}</Alert>}
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withMainLayout(Calendar);
